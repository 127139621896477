import Layout from "../components/layout";
import DynamicNav from "../components/DynamicNav"

import {myContext} from '../components/PageContext';
import React, {useEffect, useContext} from "react";

import * as sideNavStyles from '../components/sideNav.module.css'
import {Link} from 'gatsby'
import {Helmet} from "react-helmet";

export default function Media(props) {
    const context = useContext(myContext);
    const {html, content} = props.pageContext

    useEffect(() => {
        context.sideNavOn()
    }, []);

    return (
        <>
            <Helmet htmlAttributes={{lang: 'en-uk'}}>
                <meta charSet="utf-8"/>
                <title>Alan Macdonald | Media</title>
                <meta name="description" content="Media on Scottish artist Alan Macdonald."/>
                <meta name="keywords"
                      content="Alan Macdonald, video, media, videos, paintings by Alan Macdonald, Allan Macdonald, Alan Mcdonald, Allan Mcdonald, Alan MacDonald, artist, painter, painting, paintings, British artists, British painters, British art, Duncan of Jordanstone College of Art, Scottish art, Scottish painters"/>
                <meta name="author" content="Alan Macdonald"/>
                <meta charSet="utf-8"/>
                <meta property="og:title" content='Alan Macdonald'/>
                <meta property="og:description" content="Media on Scottish artist Alan Macdonald."/>
            </Helmet>
            <Layout>
                <DynamicNav location={props.location} content={content}/>
                <main
                    className={"about"}
                >
                    <h2 style={{marginTop: "2rem"}} id="videos">Media</h2>
                    <div className={sideNavStyles.sidenav_alt}>
                        <h3>Contents</h3>
                        <ul className={sideNavStyles.sidenav}>
                            <section>
                                <li><Link to={"#a-self-portrait"}>a self portrait</Link></li>
                                <li><Link to={"#scion-installation"}>scion installation</Link></li>
                            </section>
                        </ul>
                    </div>

                    <h3 id="a-self-portrait">A Self-Portrait</h3>
                    <div className="vimeo-full-width">
                        <iframe controls src="https://www.youtube.com/embed/9Litt_4JXjo" frameborder="0"/>
                    </div>
                    <h3 id="scion-installation">Scion Installation</h3>
                    <div className="vimeo-full-width">
                        <iframe src="https://player.vimeo.com/video/14594137?title=0&byline=0&portrait=0"
                                frameborder="0" allow="autoplay; fullscreen" allowfullscreen/>
                    </div>
                </main>
            </Layout>
        </>
    );
}